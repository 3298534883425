const content = {
  home: {
    teaser: {
      title:
        'Met het InterventieKompas ontdek je hoe je gewenst gedrag kunt stimuleren.',
      description:
        'Vraag jij je als handhaver, toezichthouder of beleidsontwikkelaar af waarom personen, bedrijven of organisaties de regels niet naleven?',
    },
    main: {
      title: 'InterventieKompas',
      text: 'Het instrument, ontwikkeld door het Centrum voor Criminaliteitspreventie en Veiligheid (het CCV), helpt je om stapsgewijs de motieven voor het wel of niet naleven van regels in kaart te brengen en biedt op basis van je analyse oplossingsrichtingen.',
    },
    howItWorks: {
      title: 'Hoe het InterventieKompas werkt',
      text: 'Het instrument is een gestructureerde vragenlijst waarmee jij met de doelgroep zelf of met mensen die de doelgroep kennen in gesprek kunt gaan en in vier stappen kunt achterhalen waarom een bepaalde groep zich niet (of wel) aan de regels houdt. Deze inzichten geven jou de ‘knoppen om aan te draaien’ om het gewenste gedrag te stimuleren. Daarbij geeft het InterventieKompas ter inspiratie voorbeelden van (gedrags)interventies die je kunt gebruiken voor een passende aanpak. Het instrument is gemaakt volgens de laatste inzichten binnen de gedragswetenschap.',
    },
    introSteps: {
      title: 'De stappen uit het InterventieKompas',
    },
    rightColumn: {
      title: 'CCV-trainingen',
      text: 'Wil je meer verdieping over hoe je gedragskennis toepast in je eigen praktijk? Het CCV heeft brede en actuele kennis op het gebied van gedragsbeïnvloeding en nalevingsbevordering en de invloed van organisatiecultuur. Dat brengen we graag op je over via onze trainingen. Bekijk ons <1>trainingsoverzicht</1> op de CCV-website.',
    },
    leftColumn: {
      title: 'Het CCV helpt',
      text:
        'Wil je aan de slag met het InterventieKompas, en kun je wel wat hulp gebruiken?\n\n' +
        '<1>Volg de CCV-training InterventieKompas</1> waarin je leert hoe je het instrument kunt toepassen en wat het voor jouw organisatie kan opleveren. Of schakel de procesbegeleiders van het CCV in om een sessie op maat te begeleiden.\n\n' +
        'Neem <3>contact</3> met het CCV op en vraag naar onze mogelijkheden.\n\n' +
        'Wil je liever eerst wat lezen over de werkwijze en de mechanismen en achtergronden van het Interventiekompas, lees dan de <5>handleiding</5>.',
    },
  },
  introSteps: {
    1: {
      title: 'Projectgegevens invoeren',
      text: 'Beschrijf het probleem en de regel die ervoor moet zorgen dat dit probleem wordt opgelost. Bepaal vervolgens welke doelgroep je verder gaat onderzoeken.',
    },
    2: {
      title: 'Doelgroepanalyse',
      text: 'Analyseer wat de doelgroep beweegt om zich niet aan de regel te houden. Kent de doelgroep de regel wel? Overtreedt de doelgroep de regel uit gewoonte? Of is het erg moeilijk om de regel na te leven? En wat zijn eigenlijk de risico’s als de doelgroep de regels overtreedt?',
    },
    3: {
      title: 'Doelgroepverdeling',
      text: 'Werk de doelgroep verder uit. Gaat het om bewuste of onbewuste overtreders? Zijn er personen bij die door handhaving worden afgeschrikt? Krijg zicht op verschillende typen overtreders en bepaal bij welke groep het grootste effect te bereiken is.',
    },
    4: {
      title: 'Koers bepalen',
      text: 'Alle informatie komt bij elkaar. Het InterventieKompas geeft aan waar de meeste kansen liggen voor effectief ingrijpen. Uitleg over de interventies en voorbeelden uit de praktijk helpen je verder op weg.',
    },
    5: {
      title: 'Project overzicht',
      text: 'Hier zie je in een oogopslag de visuele samenvatting van alle eerdere stappen die je hebt gezet en vind je ook de opgeslagen interventie-ideeën terug.',
    },
  },
  conditions: {
    title: 'Gebruikersvoorwaarden',
    lead: 'Het InterventieKompas is - in opdracht van het ministerie van Justitie en Veiligheid - ontwikkeld door en eigendom van het Centrum voor Criminaliteitspreventie en Veiligheid (het CCV).',
    text: `
      <h3>Disclaimer</h3>
      <p>Het CCV streeft naar een zorgvuldige controle van de informatie die we via het InterventieKompas aanbieden,
        zodat deze actueel, nauwkeurig en betrouwbaar is. Desondanks kan het CCV niet garanderen dat de informatie
        in het InterventieKompas te allen tijde actueel en correct is. Het InterventieKompas pretendeert niet
        volledig te zijn.</p>
      <h3>Eigendomsvoorbehoud</h3>
      <p>Het CCV behoudt zich het recht voor alle informatie in het InterventieKompas, op ieder moment zonder
        verdere aankondiging, te wijzigen of te verwijderen. Op alle content die via het InterventieKompas te
        raadplegen is, waaronder begrepen maar niet beperkt tot de teksten, look and feel, geluids- en
        beeldmateriaal, afbeeldingen, vormgeving, merken, logo’s alsmede enige andere informatie, rusten
        intellectuele eigendomsrechten die aan het CCV dan wel zijn licentiegevers toebehoren.</p>
      <p>Deze content mag uitsluitend gebruikt worden om van het InterventieKompas en de daarop aangeboden diensten
        gebruik te maken. Zonder uitdrukkelijke voorafgaande schriftelijke toestemming van het CCV mag de content
        niet voor andere doeleinden worden aangewend, waaronder begrepen, maar niet beperkt tot het verveelvoudigen
        en/of openbaar maken van de content dan wel het verstrekken daarvan aan derden, al dan niet voor commerciële
        doeleinden, tenzij zulks dwingend rechtelijk is toegestaan.</p>
      <h3>Aansprakelijkheid</h3>
      <p>Het CCV aanvaardt geen enkele aansprakelijkheid voor schade ontstaan door het gebruik van het
        InterventieKompas, danwel uit verleende diensten, aangeboden of verwezen content in het InterventieKompas.
        Aan de verstrekte informatie kunnen geen rechten worden ontleend. </p>
      <h3>Contactgegevens</h3>
      <p>Als je vragen hebt over deze gebruikersvoorwaarden, dan kun je die stellen via <9>mail</9>.</p>
    `,
    lastUpdated:
      'Deze gebruikersvoorwaarden zijn voor het laatst aangepast op juli 2021.',
  },
  disclaimer: {
    title: 'Colofon/Disclaimer',
    managedBy: 'Deze website wordt beheerd door',
    text: `
          <h4>Tekst</h4>
          <p>Het CCV streeft naar een zorgvuldige controle van de informatie die we via het InterventieKompas aanbieden,
            zodat deze actueel, nauwkeurig en betrouwbaar is. Desondanks kan het CCV niet garanderen dat de informatie
            in het InterventieKompas te allen tijde actueel en correct is.</p>
          <h4>Afbeeldingen</h4>
          <p>De foto’s die getoond worden bij de voorbeelden van het Interventiekompas zijn in eigen beheer gemaakt,
            aangekocht of geselecteerd op rechtenvrij gebruik. Op deze foto’s kan copyright rusten.</p>
          <h4>Links</h4>
          <p>Deze website bevat links naar websites en bestanden van derden. Deze links zijn uitsluitend ter informatie.
            Het CCV heeft geen zeggenschap over deze websites en is niet verantwoordelijk of aansprakelijk voor de
            daarop aangeboden informatie, producten of diensten. Evenmin is het CCV verantwoordelijk voor verouderde
            informatie of links die niet meer actief zijn.</p>
          <h4>Aansprakelijkheid</h4>
          <p>Het CCV aanvaardt geen enkele aansprakelijkheid voor schade ontstaan door het gebruik van het
            InterventieKompas, danwel uit verleende diensten, aangeboden of verwezen content in het InterventieKompas.
            De vlekkeloze werking van het InterventieKompas wordt nagestreefd maar niet gegarandeerd. Aan de verstrekte
            informatie kunnen geen rechten worden ontleend.</p>
          <p>Bij vragen, opmerkingen en/of klachten over het InterventieKompas kun je contact opnemen met het CCV via 
             <9>info@hetccv.nl</9>.Voor technische vragen of knelpunten kunt u terecht bij <10>tickets@hetccv.nl</10></p>

    `,
  },
  copyright: {
    title: 'Copyright',
    text: `
          <h4>Intellectuele eigendom</h4>
          <p>Het InterventieKompas is - in opdracht van het ministerie van Justitie en Veiligheid - ontwikkeld door en
            eigendom van het Centrum voor Criminaliteitspreventie en Veiligheid (het CCV).</p>
          <h4>Eigendomsvoorbehoud</h4>
          <p>Alle (aanspraken op) intellectuele eigendomsrechten (IE-rechten) met betrekking tot het InterventieKompas
            berusten bij het CCV.<br/>
            Op alle content die via het InterventieKompas te raadplegen is, waaronder begrepen maar niet beperkt tot de
            teksten, look and feel, geluids- en beeldmateriaal, afbeeldingen, vormgeving, merken, logo’s alsmede enige
            andere informatie, rusten intellectuele eigendomsrechten die aan het CCV toebehoren.<br/>
            Het CCV behoudt zich het recht voor alle informatie in het InterventieKompas, op ieder moment zonder verdere
            aankondiging, te wijzigen of te verwijderen.</p>
          <p>
            De gebruiker mag:
            <ul>
              <li>het Interventiekompas kopiëren, verspreiden, tonen en op- en uitvoeren;</li>
              <li>afgeleide werken maken;</li>
            </ul>
          </p>
          <p>
            onder de volgende voorwaarden:
            <ul>
              <li>naamsvermelding: de gebruiker dient de naam van het CCV te vermelden;</li>
              <li>niet-commercieel: de gebruiker mag het werk niet zonder toestemming voor commerciële doeleinden
                gebruiken;
              </li>
              <li>bij hergebruik of verspreiding dient de gebruiker de gebruiksvoorwaarden van het Interventiekompas
                kenbaar te maken aan derden;
              </li>
              <li>de gebruiker mag uitsluitend afstand doen van één of meerdere van deze voorwaarden met voorafgaande
                toestemming van de rechthebbende.
              </li>
            </ul>
          </p>
          <p>Het voorgaande laat de wettelijke beperkingen op de intellectuele eigendomsrechten onverlet.</p>
    `,
  },
  privacy: {
    menuTitle: 'Privacy en cookies',
    title: 'Privacy- en cookieverklaring',
    text0: `
          <h4>Algemeen</h4>
          <p>Het CCV verwerkt ten behoeve van het gebruik van het Interventiekompas persoonsgegevens. Het CCV maakt gebruik van de privacy- en cookieverklaring CCV, zoals vermeld op de website van het CCV, www.hetccv.nl, om te voldoen aan de informatieverplichtingen op grond van artikel 13 en 14 van de Algemene Verordening Gegevensbescherming.</p>
          <h4>Specifiek bij gebruik van het InterventieKompas</h4>
          <p>Door het InterventieKompas te gebruiken laat je persoonsgegevens bij het CCV achter die nodig zijn om je toegang tot jouw account op de website te geven. Het CCV bewaart en gebruikt deze gegevens alleen om op geaggregeerd niveau inzicht te krijgen in het gebruik van het InterventieKompas. Deze gegevens worden vertrouwelijk behandeld en niet met derden gedeeld. Het betreft de volgende gegevens:</p>
          <ul>
            <li>e-mailadres;</li>
            <li>voornaam;</li>
            <li>achternaam;</li>
            <li>organisatie;</li>
            <li>functie;</li>
            <li>gebruikersnaam;</li>
            <li>wachtwoord.</li>
          </ul>
          <p>Als je je gegevens (geanonimiseerd) ter beschikking stelt voor het verbeteren van het InterventieKompas, heeft het CCV tevens inzicht in de aangemaakte projecten. Deze gegevens worden zorgvuldig door het CCV bewaard. Het CCV gebruikt deze gegevens alleen om het InterventieKompas te verbeteren. Deze gegevens worden vertrouwelijk behandeld en niet met derden gedeeld. Als je je gegevens niet ter beschikking stelt, dan heeft het CCV geen toegang tot jouw projecten.</p>
          <p>Het CCV neemt beveiligingsmaatregelen om misbruik van en ongeautoriseerde toegang tot persoonsgegevens te beperken.</p>
          <h4>Bewaartermijn</h4>
          <p>Wanneer je ons toestemming hebt gegeven voor de verwerking van gegevens of je je ergens voor hebt aangemeld, dan verwerken wij de gegevens totdat je jouw toestemming intrekt of de gegevens zelf verwijdert. Accounts die langer dan twee jaar inactief zijn, worden verwijderd.</p>
          <h4>Inzage, wijzigen of verwijderen van je gegevens</h4>
          <p>Indien je via onze website aan ons persoonsgegevens hebt verstrekt, kun je het CCV een verzoek toesturen om deze gegevens in te zien, te wijzigen of te verwijderen. Je kunt hiervoor per e-mail contact met ons opnemen via <12>info@hetccv.nl</12>. Om misbruik te voorkomen, kunnen wij je daarbij vragen om je adequaat te identificeren.</p>
          <h4>Cookies</h4>
    `,
    text1: `
          <h4>Contactgegevens</h4>
          <p>Als je vragen hebt over jouw privacy of onze privacy- en cookieverklaring, dan kun je deze stellen aan onze functionaris voor de gegevensbescherming door te mailen naar privacy(at)hetccv.nl, of door telefonisch contact met de functionaris voor gegevensbescherming op te nemen via 030 - 751 6703.</p>
          <p>
            Stichting Centrum voor Criminaliteitspreventie en Veiligheid<br/>
            Churchilllaan 11, 18e etage<br/>
            3527 GV Utrecht
          </p>
          <p>KvK-nummer: 27268679</p>
          <p>Deze privacy- en cookieverklaring is voor het laatst aangepast in augustus 2021.</p>
    `,
  },
  contact: {
    title: 'Contact',
    ccvHelps: 'Het CCV helpt',
    text_1:
      'Het InterventieKompas is ontwikkeld door het expertiseteam <1>Gedrag & Samenleving</1> van het Centrum voor Criminaliteitspreventie en Veiligheid (CCV). Het CCV zet zich in voor een veilig en leefbaar Nederland. Naast het ontwikkelen van tools en het organiseren van opleidingen en trainingen op het gebied van effectief beleid en gedragsbeïnvloeding, levert het expertiseteam advies op maat aan departementen, markttoezichthouders, inspecties, uitvoeringsorganisaties met handhavingstaken en decentrale overheden.',
    text_2:
      'Wil je aan de slag met het InterventieKompas, maar kun je daar wel wat hulp bij gebruiken? Schakel de adviseurs van het CCV in om een sessie op maat te begeleiden. Neem contact op met Sara of Mirjam en vraag naar de mogelijkheden. Of volg de <1>CCV-training InterventieKompas</1> waar je leert hoe je het instrument kunt toepassen en wat het voor jouw organisatie kan opleveren.',
    questions: {
      title: 'Vragen',
      text: 'Voor technische vragen over het InterventieKompas kun je een mail sturen naar tickets@ccv.nl. Voor inhoudelijke vragen over het Interventiekompas kun je ook terecht bij:',
    },
  },
  adviceAndTraining: {
    title: 'Advies en training',
    processGuidance: {
      title: 'Advies',
      text: `
            Werken in het InterventieKompas vergt tijdsinvestering; een team is al gauw een dagdeel bezig met het 
            invullen en analyseren van de uitkomsten. De CCV-adviseurs ondersteunen je graag bij dit proces en zijn 
            bovendien experts in het gebruik van het InterventieKompas, wel zo efficiënt. Neem contact op met Sara of 
            Mirjam – zie gegevens hieronder - en vraag naar de mogelijkheden van procesbegeleiding.
      `,
    },
    training: {
      title: 'Training InterventieKompas',
      text: 'Je kunt ook de <1>training InterventieKompas</1> volgen. Tijdens de training maak je kennis met het InterventieKompas. Je leert hoe het instrument werkt en hoe je het kunt gebruiken om jouw beleid of toezicht effectiever te maken. De training gaat in op het bepalen van de doelgroep, het doelgedrag en op de verschillende dimensies uit het kompas die van invloed zijn op het gedrag. Ten slotte bepaal je aan de hand van het kompas een koers.',
    },
    targetGroup: {
      title: 'Doelgroep',
      text: 'Professionals in handhaving, toezicht en beleid. Maar ook voor professionals die het onderwerp veiligheid in hun portefeuille hebben, is deze training interessant.',
    },
    trainers: {
      title: 'Adviseurs',
    },
    signUp: {
      title: 'Meld je aan',
      text: 'Interesse in de training InterventieKompas? Neem contact op met Mirjam Prinsen om de training in te plannen.',
    },
    externalTraining: {
      text: 'Meer weten over hoe je met gedragskennis gewenst gedrag bevordert? Leer het bij de incompany training <1>Gedragsverandering voor toezicht en handhaving</1>.',
    },
  },
  accessibility: {
    menuTitle: 'Toegankelijkheidsverklaring',
    title: 'Verklaring Digitale toegankelijkheid',
    text: `
          <p>Het CCV wil dat elke overheidsprofessional het InterventieKompas kan gebruiken. Kom je toch een pagina
            tegen die niet toegankelijk is? Dan kun je dit aan ons melden.</p>
          <p>Wij streven naar een optimale gebruikerservaring en goede toegankelijkheid van het Interventiekompas door
            diverse maatregelen binnen onze processen:</p>
          <ul>
            <li>Toegankelijkheid ‘by design’: toegankelijkheid is onderdeel van alle stappen in het ontwerp-, bouw en
              redactionele proces van het Interventiekompas.
            </li>
            <li>Externe evaluatie: externe deskundigen toetsen regelmatig (onderdelen van) het Interventiekompas op
              toegankelijkheid. Zowel voor de functioneel-technische onderdelen als voor de redactionele aspecten.
              Gevonden knelpunten lossen wij binnen de budgettaire mogelijkheden op.
            </li>
            <li>Kennis medewerkers: de medewerkers van de software houden hun kennis over toegankelijkheid op peil en
              passen deze toe waar nodig.
            </li>
          </ul>
    `,
    problems: {
      title: 'Problemen met toegankelijkheid melden',
      text: 'Heb je vragen of opmerkingen? Of wil je een pagina gebruiken die niet toegankelijk blijkt? Neem dan contact op met <1>info@hetccv.nl</1>.',
    },
  },
  teamMembers: {
    mirjam: {
      role: 'Adviseur Gedrag & samenleving',
    },
    sara: {
      role: 'Adviseur Gedrag & samenleving',
    },
  },
  register: {
    agreeToTermsAndConditions:
      'Ik ga akkoord met de <1>gebruikersvoorwaarden</1>',
    researchOptin:
      'Ik stel ook mijn projectgegevens (geanonimiseerd) ter beschikking voor het verbeteren van deze tool',
    newsletterOptin:
      'Ik wil op de hoogte gehouden worden van nieuws & updates van het CCV',
    personalInformation: {
      title: 'Wat gebeurt er met mijn gegevens?',
      text: `
        <p>Door het InterventieKompas te gebruiken laat je persoonsgegevens bij het CCV achter die nodig zijn om je toegang tot jouw account op de website te geven. Het CCV bewaart en gebruikt deze gegevens alleen om op geaggregeerd niveau inzicht te krijgen in het gebruik van het InterventieKompas. Deze gegevens worden vertrouwelijk behandeld en niet met derden gedeeld.</p>
        <p>Het betreft de volgende gegevens:</p>
        <ul>
        <li>e-mailadres;</li>
        <li>voornaam;</li>
        <li>achternaam;</li>
        <li>organisatie;</li>
        <li>functie;</li>
        <li>gebruikersnaam;</li>
        <li>wachtwoord.</li>
        </ul>
        <p>Als je je gegevens (geanonimiseerd) ter beschikking stelt voor het verbeteren van het InterventieKompas, heeft het CCV tevens inzicht in de aangemaakte projecten. Deze gegevens worden zorgvuldig door het CCV bewaard. Het CCV gebruikt deze gegevens alleen om het InterventieKompas te verbeteren. Deze gegevens worden vertrouwelijk behandeld en niet met derden gedeeld. Als je je gegevens niet ter beschikking stelt, dan heeft het CCV geen toegang tot jouw projecten.</p>
        <4>Privacy- en cookieverklaring</4>
      `,
    },
  },
  forgotPassword: {
    title: 'Wachtwoord vergeten',
    text: 'Voer uw e-mailadres in. U ontvangt dan binnen enkele minuten een e-mail van ons met daarin instructies om uw wachtwoord opnieuw in te stellen.',
    success: {
      title: 'Email verzonden!',
      body: 'We hebben u een email verzonden met daarin instructies om uw wachtwoord opnieuw in te stellen.',
    },
    error: {
      invalidEmail: 'E-mailadres klopt niet of is niet bij ons bekend',
      tooManyTimes:
        'U heeft recent al een link aangevraagd om uw wachtwoord te wijzigen. Niet ontvangen? Probeer het later nogmaals.',
    },
  },
  resetPassword: {
    title: 'Wachtwoord wijzigen',
    text: 'Voer uw e-mailadres en nieuwe wachtwoord in om uw wachtwoord te wijzigen.',
    success: {
      title: 'Wachtwoord gewijzigd!',
      body: 'Wachtwoord succesvol gewijzigd. U wordt over 5 seconden automatisch ingelogd en doorverwezen naar uw projectoverzichtspagina.',
    },
    error: {
      invalidToken:
        'De URL klopt niet of is verlopen, controleer of u de link uit de e-mail correct heeft overgenomen of probeer nogmaals een nieuwe link aan te vragen.',
    },
  },
  'project.new.intake.title': 'Een goed begin',
  'project.new.intake.text':
    'Voordat je met de InterventieKompas aan de slag gaat is het goed om af te bakenen welke regel je wilt onderzoeken en welke doelgroep je daarbij voor ogen hebt. Onderstaande vragen helpen je om dat helder te krijgen. Onder de i-knoppen staan extra hulpvragen. Deze vragen helpen je bij het beantwoorden van de vragen. Je hoeft de hulpvragen niet letterlijk te beantwoorden.',
  'content.questionnaire.intro.analysis.title': 'Doelgroepanalyse',
  'content.questionnaire.intro.analysis.text':
    'Onderstaande vragen helpen je om inzicht te krijgen in waarom de doelgroep de regel wel of niet naleeft. Gaat het bijvoorbeeld om bewust of onbewust gedrag? Als je de vragen in deze lijst hebt beantwoord, weet je waar kansen liggen om naleving te bevorderen. Het is belangrijk om in de huid van de doelgroep te kruipen. Het gaat om de perceptie van de doelgroep.',
  'content.questionnaire.intro.distribution.title': 'Doelgroepverdeling',
  'content.questionnaire.intro.distribution.text':
    'Zodra je de vragen uit de doelgroepanalyse hebt beantwoord, ontstaat er een beeld van de motieven die de doelgroep kan hebben om regels na te leven of te overtreden. Of de doelgroep daadwerkelijk naleeft of overtreedt, is afhankelijk van het doorslaggevende motief. Om dit motief te bepalen, gebruik je de doelgroepverdeler. Hiermee verdeel je de doelgroep stap voor stap in verschillende soorten nalevers en niet-nalevers. Nalevers kunnen spontane nalevers, onbewuste nalevers en handhavingsafgeschrikten zijn. Niet-nalevers zijn onbewuste overtreders, gemaksovertreders, principiële overtreders of calculerende overtreders. \n\n\n',
  'content.questionnaire.distribution.unknown.title': 'Uitleg vraag 1',
  'content.questionnaire.distribution.unknown.text':
    'Schuif de cirkel op de lijn om de 100 willekeurige leden van de doelgroep te verdelen in mensen die de regel wel of niet kennen of begrijpen.',
  'content.questionnaire.distribution.unintentionalComplier.title':
    'Uitleg vraag 2',
  'content.questionnaire.distribution.unintentionalComplier.text':
    'We zoomen nu in op de mensen die de regel niet kennen of begrijpen. Schuif de cirkel op de lijn om deze groep onder te verdelen in onbewuste nalevers en onbewuste overtreders. Onbewuste overtreders overtreden de regel per ongeluk, zonder het te beseffen of omdat ze de regel niet begrijpen. Onbewuste nalevers leven de regel bij toeval na, bijvoorbeeld omdat ze doen wat anderen doen, of omdat dat onder de omstandigheden het meest logisch is.',
  'content.questionnaire.distribution.spontaneous.title': 'Uitleg vraag 3',
  'content.questionnaire.distribution.spontaneous.text':
    'We zoomen nu in op de mensen die de regel wel kennen en begrijpen. Schuif de cirkel op de lijn om aan te geven hoeveel van deze mensen de regel spontaan naleven. Spontane nalevers leven de regel na vanuit zichzelf, ze zijn intrinsiek gemotiveerd om zich aan de regel te houden. ',
  'content.questionnaire.distribution.deterred.title': 'Uitleg vraag 4',
  'content.questionnaire.distribution.deterred.text':
    'We zoomen nu in op de mensen die niet spontaan de regels naleven. Schuif de cirkel op de lijn om deze mensen te verdelen in handhavingsafgeschrikten en bewuste overtreders. Handhavingsafgeschrikten leven de regel wel na, maar alleen omdat er wordt gehandhaafd. Bewuste overtreders kiezen er bewust voor om te overtreden. ',
  'content.questionnaire.distribution.principled.title': 'Uitleg vraag 5',
  'content.questionnaire.distribution.principled.text':
    'We zoomen nu in op de mensen die de regel doelbewust overtreden. Schuif de cirkel op de lijn om deze overtreders te verdelen in principiële overtreders en niet-principiele overtreders. Principiële overtreders overtreden de regel, omdat ze het er ten diepste niet mee eens zijn, bijvoorbeeld omdat ze de regel niet logisch, niet rechtvaardig of moreel onaanvaardbaar vinden. ',
  'content.questionnaire.distribution.opportunity.title': 'Uitleg vraag 6',
  'content.questionnaire.distribution.opportunity.text':
    'We zoomen nu in op de mensen die doelbewust maar niet principieel de regel overtreden. Schuif de cirkel op de lijn om deze groep te verdelen in gemaksovertreders en calculerende overtreders. Gemakzoekers overtreden omdat overtreden makkelijk is, omdat het moeilijk is om weerstand te bieden aan de verleiding tot overtreden of omdat de gelegenheid zich nu eenmaal voordoet. Calculerende overtreders overtreden de regel, omdat ze denken dat het hen meer voordeel dan nadeel oplevert. Deze overtreders maken dus een kosten-baten analyse en overtreden de regel als de baten hoger zijn dan de kosten.  \nDe categorieën sluiten elkaar niet 100% uit. Het kan zijn dat iemand vanuit meerdere motieven handelt. Kies voor het motief dat het meest dominant is.',
  'content.questionnaire.analysis.confirm.title': 'Weet u het zeker?',
  'content.questionnaire.analysis.confirm.text':
    'Let op, je hebt nog niet op alle analysevragen een antwoord gegeven.\n\nMaak de analyse af voor een compleet inzicht.',
  'content.project.new.confirm.title': 'Weet u het zeker?',
  'content.project.new.confirm.text':
    'Let op, je hebt jouw antwoorden nog niet opgeslagen. Maak hieronder een keuze.',
  'content.questionnaire.distribution.confirm.title': 'Weet u het zeker?',
  'content.questionnaire.distribution.confirm.text':
    'Let op, je hebt jouw antwoorden nog niet opgeslagen. Maak hieronder een keuze.',
};

export default content;
